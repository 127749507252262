@font-face {
  font-family: Lato;
  font-weight: 300;
  src: url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Roboto&display=swap');
}
@font-face {
  font-family: Lato;
  font-weight: 400;
  src: url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto&display=swap');
}
@font-face {
  font-family: Lato;
  font-weight: 600;
  src: url(./assets/fonts/Lato-SemiBold.ttf);
}
@font-face {
  font-family: Lato;
  font-weight: 'Bold';
  src: url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Roboto&display=swap');
}
@font-face {
  font-family: Lato;
  font-weight: 700;
  src: url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Roboto&display=swap');
}
@font-face {
  font-family: Lato;
  font-weight: 900;
  src: url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Roboto&display=swap');
}
@font-face {
  font-family: Poppins;
  font-weight: 300;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto&display=swap');
}
@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap');
}
@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Roboto&display=swap');
}
@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Roboto&display=swap');
}
@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto&display=swap');
}
@font-face {
  font-family: Poppins;
  font-weight: 800;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&family=Roboto&display=swap');
}
@font-face {
  font-family: swiper-icons;
  font-style: normal;
  font-weight: 400;
  src: url(./assets//fonts//swiper-icons.ttf)
}
:root {
  --swiper-theme-color: #007aff;
}
.swiper {
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: 1;
}
.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}
.swiper-wrapper {
  box-sizing: content-box;
  display: flex;
  height: 100%;
  position: relative;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  width: 100%;
  z-index: 1;
}
.swiper-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.swiper-pointer-events {
  touch-action: pan-y;
}
.swiper-pointer-events.swiper-vertical {
  touch-action: pan-x;
}
.swiper-slide {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  width: 100%;
}
.swiper-slide-invisible-blank {
  visibility: hidden;
}
.swiper-autoheight,
.swiper-autoheight .swiper-slide {
  height: auto;
}
.swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: height, -webkit-transform;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}
.swiper-backface-hidden .swiper-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.swiper-3d,
.swiper-3d.swiper-css-mode .swiper-wrapper {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}
.swiper-3d .swiper-cube-shadow,
.swiper-3d .swiper-slide,
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
.swiper-3d .swiper-slide-shadow {
  background: rgba(0, 0, 0, 0.15);
}
.swiper-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.5), transparent);
}
.swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5), transparent);
}
.swiper-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
}
.swiper-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), transparent);
}
.swiper-css-mode > .swiper-wrapper {
  -ms-overflow-style: none;
  overflow: auto;
  scrollbar-width: none;
}
.swiper-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}
.swiper-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}
.swiper-horizontal.swiper-css-mode > .swiper-wrapper {
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}
.swiper-vertical.swiper-css-mode > .swiper-wrapper {
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
}
.swiper-centered > .swiper-wrapper:before {
  content: '';
  flex-shrink: 0;
  order: 9999;
}
.swiper-centered.swiper-horizontal
  > .swiper-wrapper
  > .swiper-slide:first-child {
  -webkit-margin-start: var(--swiper-centered-offset-before);
  margin-inline-start: var(--swiper-centered-offset-before);
}
.swiper-centered.swiper-horizontal > .swiper-wrapper:before {
  height: 100%;
  min-height: 1px;
  width: var(--swiper-centered-offset-after);
}
.swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child {
  -webkit-margin-before: var(--swiper-centered-offset-before);
  margin-block-start: var(--swiper-centered-offset-before);
}
.swiper-centered.swiper-vertical > .swiper-wrapper:before {
  height: var(--swiper-centered-offset-after);
  min-width: 1px;
  width: 100%;
}
.swiper-centered > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: center center;
}
:root {
  --swiper-navigation-size: 44px;
}
.swiper-button-next,
.swiper-button-prev {
  align-items: center;
  color: var(--swiper-theme-color);
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  cursor: pointer;
  display: flex;
  height: 44px;
  height: var(--swiper-navigation-size);
  justify-content: center;
  margin-top: -22px;
  margin-top: calc(0px - var(--swiper-navigation-size) / 2);
  position: absolute;
  top: 50%;
  width: 27px;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  z-index: 10;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  cursor: auto;
  opacity: 0.35;
  pointer-events: none;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 44px;
  font-size: var(--swiper-navigation-size);
  font-variant: normal;
  letter-spacing: 0;
  line-height: 1;
  text-transform: none !important;
  text-transform: none;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: 'prev';
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  left: auto;
  right: 10px;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: 'next';
}
.swiper-button-lock {
  display: none;
}
